import Button from "@/components/atoms/Button";
import { AllowedLanguages } from "@/types/generic";

const i18n = {
  nl: {
    errorCode: "Oeps...",
    title: "Er ging iets mis",
    message: "We kunnen deze pagina momenteel niet ophalen. Probeer later opnieuw of neem contact met ons op.",
    labelBtn: "Naar de startpagina",
    retryBtn: "Probeer opnieuw"
  },
  fr: {
    errorCode: "Oops...",
    title: "Quelque chose s'est mal passé\n",
    message: "Nous ne parvenons actuellement pas à récupérer cette page. Veuillez réessayer plus tard ou contactez-nous.",
    labelBtn: "Vers la page principale",
    retryBtn: "Essayer à nouveau"
  },
  en: {
    errorCode: "Oops...",
    title: "Something went wrong",
    message: "We are currently unable to retrieve this page. Please try again later or contact us.",
    labelBtn: "To the homepage",
    retryBtn: "Try again"
  },
};

interface ErrorBlockProps {
  error?: Error;
  locale: AllowedLanguages;
  reset?: () => void;
}

export const ErrorBlock = ({locale, error, reset}: ErrorBlockProps) => {
  return (
    <div className="text-center">
      <p className="text-base font-semibold text-green">{i18n[locale].errorCode}</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {error?.name ?? i18n[locale].title}
      </h1>
      <p className="mt-6 lg:mt-12 text-lg leading-7 text-gray-600">
        {error?.message ?? i18n[locale].message}
      </p>
      <div className="mt-10 lg:mt-20 flex items-center justify-center gap-x-4">
        <Button as="link" href={`/${locale}`} className="btn-outline">{i18n[locale].labelBtn}</Button>
        {reset && (
          <Button onClick={() => reset()}>{i18n[locale].retryBtn}</Button>
        )}
      </div>
    </div>
  );
};

export default ErrorBlock;
